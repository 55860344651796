import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/validator'
import './plugins/axios'
import router from './router'
import store from './store'
import api from './api'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$formatCurrency = new Intl.NumberFormat('et', {
  style: 'currency',
  currency: 'EUR'
})
Vue.prototype.$formatNumber = new Intl.NumberFormat('et', {})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
