import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views/app/Index.vue'
import Public from '../views/public/Index.vue'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Public,
    children: [{
      path: '/home/:top?',
      alias: '/',
      name: 'home',
      component: Home
    }, {
      path: '/terms',
      name: 'terms',
      component: () => import(/* webpackChunkName: "other" */ '../views/public/Terms.vue')
    }, {
      path: '/top/:top?',
      name: 'top',
      component: () => import(/* webpackChunkName: "other" */ '../views/public/Top.vue')
    }]
  },
  {
    path: '',
    component: App,
    meta: { requiresAuth: true },
    children: [{
      path: '/portfolios',
      name: 'portfolios',
      component: () => import(/* webpackChunkName: "portfolio" */ '../views/app/Portfolios.vue')
    }, {
      path: '/history/:id',
      name: 'history',
      component: () => import(/* webpackChunkName: "portfolio" */ '../views/app/History.vue')
    }, {
      path: '/portfolio/:id/analyze',
      name: 'analyze',
      component: () => import(/* webpackChunkName: "portfolio" */ '../views/app/Analyze.vue')
    }, {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ '../views/app/Profile.vue')
    }]
  }, {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  }, {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue'),
  }, {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '../views/Register.vue'),
  }, {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('logout')
      next('/')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      next({ path: '/login', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
