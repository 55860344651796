import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex)

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const store = new Vuex.Store({
  state: {
    user: null,
    token: null,
    notification: {
      type: null,
      message: null
    }
  },
  mutations: {
    setToken (state, token) {
      if (!token) {
        state.user = null
        state.token = null
        return
      }
      
      const parsedToken = parseJwt(token)
      if (moment(parsedToken.exp * 1000).isBefore(moment())) {
        state.user = null
        state.token = null
        return
      }

      state.user = parsedToken
      state.token = token
    },
    setNotification (state, notification) {
      state.notification = notification
    }
  },
  actions: {
    auth ({ commit }, token) {
      window.sessionStorage.setItem('auth', JSON.stringify(token))
      commit('setToken', token)
    },
    logout ({ commit, dispatch }) {
      window.sessionStorage.removeItem('auth')
      dispatch('showNotification', { message: 'Oled edukalt välja logitud!', type: 'success'})
      commit('setToken', null)
    },
    recoverSession({ commit }) {
      const auth = window.sessionStorage.getItem('auth')

      if (auth) {
        try {
          const token = JSON.parse(auth)
          commit('setToken', token)
        } catch (ignore) {
          // Ignore
        }
      }
    },
    showNotification({ commit }, notification) {
      commit('setNotification', notification)
    }
  },
  modules: {
  }
})

store.dispatch('recoverSession')

export default store