<template>
  <v-main>
    <v-app-bar style="max-width:1400px;margin:auto;z-index:2;" color="transparent" flat dark absolute>
      <v-toolbar-title class="d-flex align-center">
        <a class="mr-2 d-flex align-center" href="https://golive.ee" target="_blank"><img style="height:40px;" :src="logo" /></a>
        <div style="font-size:1.4em;font-weight:bold;">Investor Ranking</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/" text class="mr-3">Avaleht</v-btn>
      <v-btn to="/top" text class="mr-3">Top</v-btn>
      <template v-if="!user">
        <v-btn to="/login">Logi sisse</v-btn>
      </template>
      <template v-else>
        <v-btn to="/portfolios" text class="mr-3">Portfellid</v-btn>
        <v-btn to="/logout">Logi välja</v-btn>
      </template>
    </v-app-bar>
    <router-view /> 
    <div style="padding-top:100px;"></div>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
import logo from './../../assets/logo.svg'

export default {
  components: {},
  data () {
    return {
      logo
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>