<template>
  <div>
    <apexchart
      width="100%"
      :height="height"
      type="area"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import { colors } from './../config'

export default {
  components: {},
  data () {
    return {
      colors,
      hidden: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
      },
      lines: []
    }
  },
  props: {
    chartData: {
      required: false
    },
    height: {
      required: false,
      default: 500
    }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'area',
          height: this.height,
          toolbar: {
            tools: {
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false
            }
          }
        },
        colors,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        stroke: {
          width: [3, 3, 3, 3, 3, 5],
          curve: 'smooth'
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: (val) => {
              return val + '%'
            }
          }
        },
        tooltip: {
          shared: false,
          intersect: false,
          y: {
            formatter: function (val) {
              return val + '%'
            }
          }
        },
      }
    },
    series () {
      return this.chartData.map((line) => {
        const first = line.data[0]
        return {
          name: this.getName(line.portfolio),
          data: line.data.map(point => ({
            x: point.time * 1000,
            y: Math.round((point.value - first.value) / first.value * 100, 2)
          }))
        }
      })
    }
  },
  methods: {
    getName (portfolio) {
      return portfolio.displayName
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  margin-top: 0px;
}
.profile * {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.info-area {
  z-index: 2;
  background: #fff;
  position: absolute;
  top: -15px;
  left: 0;
}
</style>