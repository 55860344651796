<template>
  <v-footer dark padless class="footer">
    <v-card class="flex" flat tile>
      <v-card-title class="secondary">
        <v-container style="max-width:1260px;z-index:3;" class="pa-0 d-flex align-center">
          <span class="text-caption">©Taavi Ilves 2020</span>
          <v-spacer></v-spacer>
          <v-btn href="mailto:info@golive.ee" class="mx-1" dark icon><v-icon>mdi-email</v-icon></v-btn>
          <v-btn href="https://www.facebook.com/groups/finantsvabadus/" class="mx-1" dark icon><v-icon>mdi-facebook</v-icon></v-btn>
          <v-btn href="https://discord.gg/9khqvcU8XR" class="mx-1" dark icon><v-icon>mdi-discord</v-icon></v-btn>
        </v-container>
      </v-card-title>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  
}
</script>

<style lang="scss">
.footer {
  position: absolute !important;
  bottom: 0px;
  right:0;
  left:0;
  z-index: 10;
}
</style>

