export const TopMixin = {
  data () {
    return {
      MAIN_CONTEST: 2024,
      contest: {
        contestId: this.$route.params.top || 2024,
        league: this.$route.query.league
      }
    }
  },
  computed: {
    options () {
      return [{
        text: '2024 Üldine',
        value: {
          contestId: 2024,
          league: undefined
        }
      }, {
        text: '2023 Üldine',
        value: {
          contestId: 2023,
          league: undefined
        }
      }, {
        text: '2022 Üldine',
        value: {
          contestId: 2022,
          league: undefined
        }
      }, {
        text: '2021 Üldine',
        value: {
          contestId: 2021,
          league: undefined
        }
      }, {
        text: '2020 Üldine',
         value: {
          contestId: 2020,
          league: undefined
        }
      }]
    },
    topYear () {
      return this.isContest ? 2024 : this.contest.contestId
    },
    isContest () {
      return this.contest.contestId == this.MAIN_CONTEST
    }
  },
  watch: {
    contest () {
      this.$router.push({
        params: {
          top: this.contest.contestId
        },
        query: {
          league: this.contest.league
        }
      })
      this.loadYearData()
    }
  }
}
