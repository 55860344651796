<template>
  <span :class="{ [color]: true, 'font-weight-bold': bold }">
    {{formatedValue}}
  </span>
</template>

<script>
  const formatter = new Intl.NumberFormat('et', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  const navVormatter = new Intl.NumberFormat('et', { maximumFractionDigits: 4, minimumFractionDigits: 4 });


  const symbols = {
    money: '€',
    percent: '%'
  }

  export default {
    props: {
      value: {
        required: true
      },
      type: {
        required: true
      },
      forceNeutral: {
        required: false
      },
      bold: {
        required: false,
        default: true
      }
    },
    computed: {
      formatedValue () {
        const after =  symbols[this.type] || ''
        const before = this.value > 0 && !this.forceNeutral ? '+' : ''
        const format = this.type === 'nav' ? navVormatter : formatter

        return before + format.format(this.value) + after
      },
      color () {
        if (!this.forceNeutral && this.value > 0) {
          return 'value-green'
        }
        if (!this.forceNeutral && this.value < 0) {
          return 'value-red'
        }
        return 'value-neutral'
      }
    }
  }
</script>

<style lang="scss">
.value-green {
  color:#26a69a;
}
.value-red {
  color:#ef5350;
}
.value-neutral {
  color:#131722;
}
</style>