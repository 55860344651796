<template>
  <div>
    <hero>
      <v-container style="max-width:1400px;">
        <v-row>
          <v-col class="col-12 col-md-6">
            <h1 class="text-h3 white--text"><strong>Portfelli tootlus</strong></h1>
            <p class="mt-2 text-h5 white--text">
              Registreeri ja sisesta oma igakuine portfelli väärtus ning võrdle oma tootlust teiste tuntud ja vähemtuntud investorite ja võrdlusindeksitega!
              <strong>Kuvatakse ainult portfelle, mille andmeid on uuendatud 60 päeva jooksul.</strong>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </hero>
    <v-container style="margin-top:-250px;max-width:1400px;z-index:3;position:relative;">
      <h1 class="text-h3 white--text mb-10 d-flex align-center" style="z-index:3;">
        <span class="mr-5">Top:</span>
        <v-select
          v-model="contest"
          :items="options"
          label="Võistlus"
          filled
          hide-details
          color="white"
        />
      </h1>
      <v-row>
        <v-col class="col-12 col-md-12 col-xl-7" v-if="false">
          <v-card elevation="6">
            <v-card-title>
              Parim Investor 2024 võistlus
            </v-card-title>
            <top-table :items="topNav" :showPosition="true" />
            <v-card-actions>
              <v-btn :to="{name: 'top', params: { top: this.contest.contestId }, query: { league: this.contest.league }}" block color="green" tile text>Vaata edasi</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-card elevation="6">
            <v-card-title>
              Tootlus (%)
            </v-card-title>
            <top-table :items="topNav" :showHistory="true" :year="topYear" />
            <v-card-actions>
              <v-btn :to="{name: 'top', params: { top: this.contest.contestId }, query: { league: this.contest.league }}" block color="green" tile text>Vaata edasi</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-card elevation="6">
            <v-card-title>
              Tootlus (absoluut)
            </v-card-title>
            <top-table :items="topValue" />
            <v-card-actions></v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-card elevation="6">
            <v-card-title>
              Kuulsused
            </v-card-title>
            <top-table :items="topFeatured" />
            <v-card-actions></v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mt-4 mb-4" elevation="6">
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <chart :chartData="chartData" />
              <div v-if="user">
                <v-select light class="mt-2" v-model="showMyPortfolio" :items="myPortfolioOptions" label="Võrdle minu portfelliga"></v-select>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Hero from '../components/Hero.vue'
import Chart from '../components/Chart.vue'
import TopTable from '../components/TopTable.vue'
import moment from 'moment'
import { TopMixin } from '../mixins/top.mixin'
import { mapPortfolioData } from '../helpers'

export default {
  mixins: [ TopMixin ],
  components: {
    Hero,
    Chart,
    TopTable
  },
  data () {
    return {
      topContest: [],
      topNav: [],
      topValue: [],
      topFeatured: [],
      chartData: [],
      showMyPortfolio: null,
      myPortfolios: [],
      myPortfolioData: null
    }
  },
  computed: {
    ...mapState(['user']),
    lastUpdate () {
      if (!this.topNav || !this.topNav[0]) {
        return
      }
      return moment(this.topNav[0].top.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    myPortfolioOptions () {
      return this.myPortfolios.map(portfolio => ({
        text: portfolio.title,
        value: portfolio
      }))
    }
  },
  watch: {
    topNav () {
      this.loadChartData(this.topNav)
    },
    showMyPortfolio () {
      this.loadChartData(this.topNav)
    }
  },
  methods: {
    async loadChartData (list) {
      const promises = list.slice(0, 5).map(portfolio => {
        return this.$api.getPortfolioHistory(portfolio.id, this.topYear).then(response => {
          return mapPortfolioData(response, portfolio)
        })
      })
      if (this.showMyPortfolio) {
        promises.push(this.$api.getPortfolioHistory(this.showMyPortfolio.id, this.topYear).then(response => {
          return mapPortfolioData(response, this.showMyPortfolio)
        }))
      }
      
      this.chartData = await Promise.all(promises)
    },
    loadYearData () {
      if (this.contest.contestId !== 'league') {
        this.$http.get('/api/portfolio/top/nav/' + this.contest.contestId).then(response => {
          this.topNav = response.data.slice(0, 5)
        })
        this.$http.get('/api/portfolio/top/value/' + this.contest.contestId).then(response => {
          this.topValue = response.data.slice(0, 5)
        })
        this.$http.get('/api/portfolio/top/featured/' + this.contest.contestId).then(response => {
          this.topFeatured = response.data
        })
      } else {
        this.topNav = []
        this.$http.get('/api/portfolio/top/2024?league=' + this.contest.league).then(response => {
          this.topNav = response.data.slice(0, 10)
        })
      }
    },
    loadMyPortfolios () {
      if (!this.user) {
        this.portfolios = []
      } else {
        this.$http.get('/api/portfolio/me').then(response => {
          this.myPortfolios = response.data.data
        })
      }
    }
  },
  mounted () {
    this.loadYearData()
    this.loadMyPortfolios()
  }
}
</script>

<style lang="scss" scoped>
.profile > * {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>