import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './../store'

axios.interceptors.request.use(function (config) {
  if (store.state.token) {
    config.headers = { 
      'Authorization': `Bearer ${store.state.token}`,
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

Vue.use(VueAxios, axios);
