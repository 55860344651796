import axios from './api-client'

const cache = {}

export class Api {
  getPortfolio (portfolioId) {
    return axios.get('/api/portfolio/' + portfolioId).then(response => {
      return response.data
    })
  }
  getHistory (portfolioId) {
    return axios.get('/api/portfolio/' + portfolioId + '/history').then(response => {
      return response.data
    })
  }
  getPortfolioHistory (portfolioId, year) {
    return this._cache('portfolio_history_' + portfolioId + '_' + year, async () => {
      return axios.get('/api/portfolio/' + portfolioId + '/history/' + year + '/public').then(response => {
        return response.data
      })
    })
  }
  getPortfolioHistoryPublic (portfolioId) {
    return this._cache('portfolio_history_' + portfolioId + '_public', async () => {
      return axios.get('/api/portfolio/' + portfolioId + '/history/public').then(response => {
        return response.data
      })
    })
  }
  getPortfolioNavAnalyze (portfolioId, today) {
    return axios.get('/api/analyze/' + portfolioId + '/nav', {
      params: {
        today
      }
    }).then(response => {
      return response.data
    })
  }
  deletePortfolio (portfolioId) {
    return axios.delete('/api/portfolio/' + portfolioId)
  }
  async _cache (key, getter, ttl = 60 * 1000) {
    if (!cache[key] || cache[key].expires > Date.now()) {
      cache[key] = {
        expires: Date.now() + ttl,
        value: getter()
      }
    }

    return cache[key].value
  }
}

export default new Api()
