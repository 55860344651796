<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-list-item :key="item.text" :to="item.to" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title>Investor Ranking</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view/> 
      <div style="padding-top:100px;"></div>
    </v-main>
  </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: null,
      items: [{
        icon: 'mdi-home',
        text: 'Avalehele',
        to: '/'
      }, {
        icon: 'mdi-folder',
        text: 'Portfellid',
        to: '/portfolios'
      }, {
        icon: 'mdi-account',
        text: 'Profiil',
        to: '/profile'
      }, {
        icon: 'mdi-logout',
        text: 'Logi välja',
        to: '/logout'
      }]
    }
  }
}
</script>