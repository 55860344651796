<template>
  <div> 
    <v-avatar tile color="grey lighten-1" size="48" class="mr-2">
      <img style="object-fit: cover;" v-if="avatarUrl" :src="avatarUrl">
      <span v-else class="white--text">{{shortName}}</span>
    </v-avatar>
    <span>{{ name }}</span>
    <v-icon v-if="!hideBadge && portfolio.user.verified" size="12" class="" color="green">mdi-shield-star-outline</v-icon>
  </div>
</template>

<script>
  export default {
    props: {
      portfolio: {
        required: true
      },
      hideBadge: {
        required: false,
        default: false
      }
    },
    computed: {
      isIndex () {
        return this.portfolio.isIndex
      },
      avatarUrl () {
        return this.isIndex || !this.portfolio.user ? '/api/portfolio/' + this.portfolio.id + '/avatar' : '/api/users/' + this.portfolio.user.id + '/avatar'
      },
      shortName () {
        return this.isIndex || !this.portfolio.user ? '' : this.portfolio.user.shortName
      },
      name () {
        return this.portfolio.displayName
      }
    }
  }
</script>
