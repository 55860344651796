<template>
  <div>
    <v-progress-linear v-if="showLoader" color="primary accent-4" indeterminate  height="6" />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="showPosition" class="pr-0" style="width:1px;"></th>
            <th></th>
            <th v-if="showType" class="text-right">
              <v-select
                class="mt-1"
                :items="portfolioTypeOptions"
                v-model="selectedPortfolioType"
                label="Tüüp"
                outlined
                dense
              />
            </th>
            <th v-if="showIncrease" class="text-right">Kasum/kahjum</th>
            <th class="text-right">Tootlus</th>
            <th v-if="showValue" class="text-right">Portfelli väärtus</th>
            <th v-if="showHistory" class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(portfolio, k) in data" :key="portfolio.id">
            <td v-if="showPosition" class="pr-0">{{k+1}}.</td>
            <td class="profile"><portfolio-profile :portfolio="portfolio" /></td>
            <td v-if="showType" class="text-right pr-0">{{typeLabel(portfolio)}}</td>
            <td v-if="showIncrease" class="text-right pr-0 pl-0"><value :value="portfolio.top.adjustedPortfolioIncrease" type="money" /></td>
            <td class="text-right pl-2"><value :value="portfolio.top.performance" type="percent" /></td>
            <td v-if="showValue" class="text-right pl-0"><value :value="portfolio.top.portfolioValue" :forceNeutral="true" type="money" /></td>
            <td v-if="showHistory" style="width:100px;" class="text-center pl-0">
              <apexchart
                v-if="history[portfolio.id]"
                type="area"
                height="35"
                width="100"
                :options="chartOptions(k)"
                :series="history[portfolio.id]"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="lastUpdate">
          <tr>
            <td class="text-caption text-right" :colspan="columnsCount">Viimati uuendatud: <strong>{{lastUpdate}}</strong></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from 'moment'
import PortfolioProfile from './PortfolioProfile.vue'
import Value from './Value.vue'
import { colors } from './../config'

export default {
  components: { PortfolioProfile, Value },
  data () {
    return {
      selectedPortfolioType: null,
      history: {}
    }
  },
  props: {
    items: {
      required: true,
      default: []
    },
    showPosition: {
      required: false,
      default: false
    },
    showType: {
      required: false,
      default: false
    },
    showHistory: {
      required: false,
      default: false
    },
    portfolioType: {
      required: false,
      default: null
    },
    year: {
      required: false,
      default: null
    }
  },
  watch: {
    selectedPortfolioType (type) {
      this.$emit('update:portfolioType', type)
    }
  },
  computed: {
    data () {
      if (!this.items) {
        return []
      }
      return this.items.map(item => {
        if (this.showHistory) {
          item.history = []
          this.loadHistory(item)
        }
        return item
      })
    },
    lastUpdate () {
      if (!this.data[0]) {
        return
      }
      return moment(this.data[0].top.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    showIncrease () {
      return this.data[0] && this.data[0].top.adjustedPortfolioIncrease
    },
    showValue () {
      return this.data[0] && this.data[0].top.portfolioValue
    },
    columnsCount () {
      let columns = 2
      if (this.showIncrease) columns++
      if (this.showValue) columns++
      if (this.showPosition) columns++
      if (this.showType) columns++
      if (this.showHistory) columns++
      return columns
    },
    showLoader () {
      return this.items === null
    },
    portfolioTypeOptions () {
      return [{
        text: 'Kõik',
        value: null
      }, {
        text: 'Investor',
        value: 'DEFAULT'
      }, {
        text: 'Pensionifond',
        value: 'PENSION'
      }, {
        text: 'Indeks',
        value: 'INDEX'
      }]
    },
    sparklineOptions () {
      return {
        chart: {
          type: 'area',
          width: 100,
          height: 35,
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          width: [1],
          curve: 'smooth'
        },
        colors: ['rgba(33, 150, 243, 1)'],
        xaxis: {
          axisBorder: {
            show: true,
            color: '#78909C',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
          },
        },
        tooltip: {
          enabled: false,
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          },
          marker: {
            show: false
          }
        }
      }
    }
  },
  methods: {
    typeLabel (portfolio) {
      switch (portfolio.type) {
        case 'PENSION':
          return 'Pensionifond'
        case 'INDEX':
          return 'Indeks'
        default:
          return 'Investor'
      }
    },
    chartOptions (n) {
      return {
        ...this.sparklineOptions,
        colors: [colors[n%colors.length]]
      }
    },
    loadHistory (portfolio) {
      return this.$api.getPortfolioHistory(portfolio.id, this.year).then(response => {
        this.$set(this.history, portfolio.id, [{
          data: response.map(item => item.nav)
        }])
      })
    }
  }
}
</script>
