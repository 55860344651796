<template>
  <v-snackbar v-model="visible" :multi-line="true" :color="color">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="visible = false">
        Sulge
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const colors = {
  error: 'red',
  success: 'green'
}

export default {
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(['notification']),
    text () {
      return this.notification.message
    },
    color () {
      return colors[this.notification.type]
    }
  },
  watch: {
    'notification.message' (message) {
      this.visible = !!message

      if (!this.visible) {
        return
      }

      setTimeout(() => {
        this.visible = false
        this.showNotification({})
      }, 15 * 1000)
    }
  },
  methods: {
    ...mapActions(['showNotification'])
  }
}
</script>