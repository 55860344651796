import moment from 'moment'

export const mapPortfolioData = (historyList, portfolio, opts = {}) => {
  const { minDate, maxDate, key = 'nav', includePrev = false } = opts

  return {
    portfolio,
    data: historyList
      .filter((item, index, list) => {
        if (!minDate) {
          return true
        }
        if (moment(item.date).isSameOrAfter(minDate, 'day')) {
          return true
        }
        const next = list[index + 1]
        if (includePrev && next && moment(next.date).isSameOrAfter(minDate, 'day')) {
          return true
        }
        return false
      })
      .filter((item) => {
        if (!maxDate) {
          return true
        }
        if (moment(item.date).isSameOrBefore(maxDate, 'day')) {
          return true
        }
        return false
      })
      .map(item => {
        return { time: moment(item.date).endOf('day').unix(), value: item[key] }
      })
  }
}
